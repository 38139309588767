<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            class="mr-2"
            @click="onShowAddForm"
          >
            Add Lead
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditForm(row.item)"
            >
              Update
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-lead-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      size="xl"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-tabs
            class="px-2"
            no-body
          >
            <b-tab
              title="Personal Information"
              active
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="first name"
                    vid="first_name"
                    rules="required|max:50"
                  >
                    <b-form-group>
                      <label for="first_name"><strong>First Name</strong></label>
                      <b-input
                        id="first_name"
                        v-model="facebookLead.first_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                        placeholder="enter first name"
                        autocomplete="off"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="last name"
                    vid="last_name"
                    rules="required|max:50"
                  >
                    <b-form-group>
                      <label for="last_name"><strong>Last Name</strong></label>
                      <b-input
                        id="last_name"
                        v-model="facebookLead.last_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                        placeholder="enter last name"
                        autocomplete="off"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="mobile number"
                    vid="mobile_number"
                    rules="min:11|max:11"
                  >
                    <b-form-group>
                      <label for="mobile_number"><strong>Mobile Number</strong></label>
                      <b-input
                        id="mobile_number"
                        v-model="facebookLead.mobile_number"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                        placeholder="enter mobile number"
                        autocomplete="off"
                        maxlength="11"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="lead type"
                    vid="customer_type"
                  >
                    <b-form-group>
                      <label for="customer_type"><strong>Customer Type</strong></label>
                      <b-form-select
                        id="customer_type"
                        v-model="facebookLead.customer_type"
                        :options="options.customer_types"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Customer Type --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="gender"
                    vid="gender"
                  >
                    <b-form-group>
                      <label for="gender"><strong>Gender</strong></label>
                      <b-form-select
                        id="gender"
                        v-model="facebookLead.gender"
                        :options="options.genders"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Gender --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="birth_date"
                    vid="birth_date"
                    rules=""
                  >
                    <b-form-group>
                      <label for="date">Birth Date</label>
                      <b-input
                        id="date"
                        v-model="facebookLead.birth_date"
                        type="date"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter date"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="street name"
                    vid="street_name"
                    rules=""
                  >
                    <b-form-group>
                      <label for="remarks"><strong>Street Name (Home Address)</strong></label>
                      <b-input
                        id="street_name"
                        v-model="facebookLead.street_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter street name"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="province"
                    vid="province"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="province"><strong>Province (Home Address)</strong></label>
                      <v-select
                        id="province"
                        v-model="selected.province"
                        :options="list.provinces"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        :disabled="state.busy || state.fetching.provinces"
                        :loading="state.fetching.provinces"
                        label="province_name"
                      >
                        <template #option="{ province_name }">
                          <strong>{{ province_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Provinces
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="municipality"
                    vid="municipality"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="municipality"><strong>Municipality (Home Address)</strong></label>
                      <v-select
                        id="municipality"
                        v-model="selected.municipality"
                        :options="list.municipalities"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        :disabled="state.busy || state.fetching.municipalities"
                        :loading="state.fetching.municipalities"
                        label="municipality_name"
                      >
                        <template #option="{ municipality_name }">
                          <strong>{{ municipality_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Municipalities
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="barangay"
                    vid="barangay"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="barangay"><strong>Barangay (Home Address)</strong></label>
                      <v-select
                        id="barangay"
                        v-model="selected.barangay"
                        :options="list.barangays"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        :disabled="state.busy || state.fetching.barangays"
                        :loading="state.fetching.barangays"
                        label="barangay_name"
                      >
                        <template #option="{ barangay_name }">
                          <strong>{{ barangay_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Barangays
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              title="Facebook Disposition"
            >
              <b-row>
                <b-col cols="12">
                  <b-table
                    ref="tableFacebookDisposition"
                    hover
                    responsive
                    class="mt-2"
                    :per-page="tableFacebookLogSettings.perPage"
                    :current-page="tableFacebookLogSettings.currentPage"
                    :items="myLogProvider"
                    :fields="tableFacebookLogSettings.fields"
                    :sort-by.sync="tableFacebookLogSettings.sortBy"
                    :sort-desc.sync="tableFacebookLogSettings.sortDesc"
                    :sort-direction="tableFacebookLogSettings.sortDirection"
                    :filter="tableFacebookLogSettings.filter"
                    :filter-included-fields="tableFacebookLogSettings.filterOn"
                    show-empty
                  >
                    <template #cell(index)="data">
                      {{ tableFacebookLogSettings.currentPage * tableFacebookLogSettings.perPage - tableFacebookLogSettings.perPage + (data.index + 1) }}
                    </template>

                    <template #cell()="data">
                      <div class="text-nowrap">
                        {{ data.value }}
                      </div>
                    </template>

                    <template #cell(actions)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          variant="secondary"
                          class="mr-2"
                          @click="onViewCommunicationDetail(row.item)"
                        >
                          View
                        </b-button>
                      </div>
                    </template>

                  </b-table>

                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                        <b-select
                          v-model="tableFacebookLogSettings.perPage"
                          :options="tableFacebookLogSettings.pageOptions"
                          size="sm"
                        />
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex justify-content-center justify-content-sm-end"
                    >
                      <b-pagination
                        v-model="tableFacebookLogSettings.currentPage"
                        :total-rows="tableFacebookLogSettings.totalRows"
                        :per-page="tableFacebookLogSettings.perPage"
                        first-number
                        last-number
                        pills
                        prev-text="Prev"
                        next-text="Next"
                        aria-controls="table"
                      />
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12">
                  <b-form-group>
                    <label><strong>Previous Remarks</strong></label>
                    <b-textarea
                      :value="allComments"
                      rows="10"
                      max-rows="10"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-3 mt-5"
                >
                  <b-form-checkbox
                    id="validated"
                    v-model="facebookLead.validated"
                    name="validated"
                    value="1"
                    unchecked-value="0"
                    :disabled="state.busy || facebookLead.mobile_number?.length !== 11 || facebookLead.customer_type !== 'Raiser'"
                    switch
                  >
                    Successfully validated lead information via phone call?
                  </b-form-checkbox>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="disposition type"
                    vid="disposition_type"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="disposition_type"><strong>Disposition Type</strong></label>
                      <v-select
                        id="disposition_type"
                        v-model="selected.disposition_type"
                        :options="list.disposition_types"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="type_name"
                        :disabled="state.busy"
                        placeholder="-- Please Select Disposition Type --"
                      >
                        <template #option="{ type_name }">
                          <span>{{ type_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Disposition Types
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="disposition"
                    vid="disposition"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="sub_disposition"><strong>Disposition</strong></label>
                      <v-select
                        id="disposition"
                        v-model="selected.disposition"
                        :options="list.dispositions"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="disposition_name"
                        :disabled="state.busy"
                        placeholder="-- Please Select Disposition --"
                      >
                        <template #option="{ disposition_name }">
                          <span>{{ disposition_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Dispositions
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="promotion"
                    vid="promotion"
                    :rules="rulesPromotion"
                  >
                    <b-form-group>
                      <label for="promotion"><strong>Promotion</strong></label>
                      <v-select
                        id="promotion"
                        v-model="selected.promotion"
                        :options="list.promotions"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="promotion_name"
                        :disabled="(state.busy || facebookLead.disposition === null || parseInt(facebookLead.disposition)!==1)"
                        placeholder="-- Please Select Promotion --"
                      >
                        <template #option="{ promotion_name }">
                          <span>{{ promotion_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Promotions
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                    rules="required|max:1000"
                  >
                    <b-form-group>
                      <label for="remarks"><strong>Remarks</strong></label>
                      <b-textarea
                        id="remarks"
                        v-model="facebookLead.remarks"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter remarks"
                        autocomplete="off"
                        rows="6"
                        max-rows="6"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-tab>

          </b-tabs>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-communication-detail"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposed At</label>
            <b-input
              :value="dateTimeFormatter(selected.log?.created_at)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Agent</label>
            <b-input
              :value="selected.log?.agent_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition Type</label>
            <b-input
              :value="selected.log?.disposition_type_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition</label>
            <b-input
              :value="selected.log?.disposition_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Promotion</label>
            <b-input
              :value="selected.log?.promotion_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AgentFacebookLeadService, SharedLogService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AgentFacebookLeads',

  middleware: ['auth', 'agent'],

  metaInfo () {
    return {
      title: 'Facebook Leads'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        uploading: false,
        fetching: {
          provinces: false,
          municipalities: false,
          barangays: false
        }
      },
      options: {
        customer_types: [
          { text: 'Raiser', value: 'Raiser' },
          { text: 'Non-Raiser', value: 'Non-Raiser' }
        ],
        genders: [
          { text: 'Male', value: 'Male' },
          { text: 'Female', value: 'Female' }
        ]
      },
      list: {
        provinces: [],
        municipalities: [],
        barangays: [],
        disposition_types: [],
        dispositions: [],
        promotions: []
      },
      selected: {
        log: null,
        current: null,
        province: null,
        municipality: null,
        barangay: null,
        disposition_type: null,
        disposition: null,
        promotion: null
      },
      facebookLead: {
        id: 0,
        first_name: '',
        last_name: '',
        mobile_number: '',
        customer_type: null,
        gender: null,
        street_name: '',
        province: null,
        municipality: null,
        barangay: null,
        disposition_type: null,
        disposition: null,
        promotion: null,
        remarks: '',
        validated: 0
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'customer_type' },
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'mobile_number' },
          { key: 'gender' },
          { key: 'last_agent_name', label: 'last agent' },
          { key: 'disposition_type_name', label: 'last disposition type' },
          { key: 'disposition_name', label: 'last disposition' },
          { key: 'promotion_name', label: 'promotion' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      tableFacebookLogSettings: {
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'agent_name', label: 'agent' },
          { key: 'disposition_type_name', label: 'disposition type' },
          { key: 'disposition_name', label: 'disposition' },
          { key: 'promotion_name', label: 'promotion' }
        ]
      },
      allComments: ''
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Update Lead' : 'Add Lead'
    },
    rulesPromotion () {
      if (parseInt(this.facebookLead.disposition_type) === 8) {
        return 'required'
      }

      return ''
    }
  },

  watch: {
    'facebookLead.mobile_number' (value) {
      if (value?.length !== 11) {
        this.facebookLead.validated = 0
      }
    },

    'selected.province' (value) {
      this.facebookLead.province = value?.id || null
      this.getMunicipalities(this.facebookLead.province)
    },

    'selected.municipality' (value) {
      this.facebookLead.municipality = value?.id || null
      this.getBarangays(this.facebookLead.municipality)
    },

    'selected.barangay' (value) {
      this.facebookLead.barangay = value?.id || null
    },

    'selected.disposition_type' (value) {
      this.facebookLead.disposition_type = value?.id || null
      this.getDispositions(this.facebookLead.disposition_type)
    },

    'selected.disposition' (value) {
      this.facebookLead.disposition = value?.id || null
      if (this.facebookLead.disposition === null) {
        this.selected.promotion = null
      } else {
        if (parseInt(this.facebookLead.disposition) !== 1) {
          this.selected.promotion = null
        }
      }
    },

    'selected.promotion' (value) {
      this.facebookLead.promotion = value?.id || null
    }
  },

  mounted () {
    core.index()
    this.getProvinces()
    this.getDispositionTypes()
    this.getPromotions()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AgentFacebookLeadService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async myLogProvider (ctx) {
      try {
        const { data } = await SharedLogService.getFacebookLogs(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            facebook_lead_id: this.facebookLead.id
          })
        )
        this.tableFacebookLogSettings.totalRows = data.total_rows
        this.allComments = ''
        data.items.forEach(log => {
          const remarks = `${this.dateTimeFormatterLong(log.created_at)} [${log.agent_name} thru facebook]: ${log.remarks || ''}\r\n\r\n`
          this.allComments += remarks
        })
        return data.items
      } catch (e) {
        return []
      }
    },

    async getProvinces () {
      this.state.fetching.provinces = true
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provinces = data
        this.state.fetching.provinces = false
      })
    },

    async getMunicipalities (province) {
      this.selected.municipality = null
      if (province) {
        this.state.fetching.municipalities = true
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities = data
          this.state.fetching.municipalities = false
          if (this.state.editing) {
            if (this.selected.current.municipality) {
              this.selected.municipality = {
                id: this.selected.current.municipality.id,
                municipality_name: this.selected.current.municipality.municipality_name
              }
              this.selected.current.municipality = null
            }
          }
        })
      } else {
        this.list.municipalities = []
      }
    },

    async getBarangays (municipality) {
      this.selected.barangay = null
      if (municipality) {
        this.state.fetching.barangays = true
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangays = data
          this.state.fetching.barangays = false
          if (this.state.editing) {
            if (this.selected.current.barangay) {
              this.selected.barangay = {
                id: this.selected.current.barangay.id,
                barangay_name: this.selected.current.barangay.barangay_name
              }
              this.selected.current.barangay = null
            }
          }
        })
      } else {
        this.list.barangays = []
      }
    },

    async getDispositionTypes () {
      await SharedListService.getDispositionTypes('channel_id=2').then(({ data }) => {
        this.list.disposition_types = data
      })
    },

    async getDispositions (dispositionType) {
      this.selected.disposition = null
      if (dispositionType) {
        await SharedListService.getDispositions(`disposition_type_id=${dispositionType}`).then(({ data }) => {
          this.list.dispositions = data
        })
      } else {
        this.list.dispositions = []
      }
    },

    async getPromotions () {
      await SharedListService.getPromotions().then(({ data }) => {
        this.list.promotions = data
      })
    },

    onShowAddForm () {
      this.state.editing = false
      this.facebookLead.id = 0
      this.facebookLead.active = 1
      this.facebookLead.first_name = ''
      this.facebookLead.last_name = ''
      this.facebookLead.mobile_number = ''
      this.facebookLead.customer_type = null
      this.facebookLead.gender = null
      this.facebookLead.birth_date = null
      this.facebookLead.street_name = ''
      this.facebookLead.province = null
      this.facebookLead.municipality = null
      this.facebookLead.barangay = null
      this.facebookLead.remarks = ''

      this.selected.province = null
      this.selected.municipality = null
      this.selected.barangay = null
      this.$bvModal.show('modal-lead-form')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.selected.current = { ...current }
      this.selected.source = null
      this.selected.province = null
      this.selected.municipality = null
      this.selected.barangay = null
      this.selected.disposition_type = null
      this.selected.disposition = null
      this.selected.promotion = null
      this.facebookLead.id = current.id
      this.facebookLead.active = current.active
      this.facebookLead.first_name = current.first_name
      this.facebookLead.last_name = current.last_name
      this.facebookLead.mobile_number = current.mobile_number
      this.facebookLead.customer_type = current.customer_type
      this.facebookLead.gender = current.gender
      this.facebookLead.birth_date = current.birth_date
      this.facebookLead.street_name = current.street_name
      this.facebookLead.remarks = current.remarks

      if (current.province) {
        this.selected.province = {
          id: current.province.id,
          province_name: current.province.province_name
        }
      }

      if (current.municipality) {
        this.selected.municipality = {
          id: current.municipality.id,
          municipality_name: current.municipality.municipality_name
        }
      }

      if (current.barangay) {
        this.selected.barangay = {
          id: current.barangay.id,
          barangay_name: current.barangay.barangay_name
        }
      }

      this.$bvModal.show('modal-lead-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to add facebook lead?'

          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AgentFacebookLeadService.post(this.facebookLead).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-lead-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AgentFacebookLeadService.put(this.facebookLead).then(({ data }) => {
          this.$bvModal.hide('modal-lead-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.facebook_lead.id })
            if (parseInt(this.facebookLead.validated) === 1) {
              this.$refs.table.refresh()
            } else {
              row.active = data.facebook_lead.active
              row.first_name = data.facebook_lead.first_name
              row.last_name = data.facebook_lead.last_name
              row.mobile_number = data.facebook_lead.mobile_number
              row.customer_type = data.facebook_lead.customer_type
              row.gender = data.facebook_lead.gender
              row.birth_date = data.facebook_lead.birth_date
              row.street_name = data.facebook_lead.street_name
              row.province = data.facebook_lead.province
              row.municipality = data.facebook_lead.municipality
              row.barangay = data.facebook_lead.barangay
              row.last_agent_name = data.facebook_lead.last_agent_name
              row.disposition_type_name = data.facebook_lead.disposition_type_name
              row.disposition_name = data.facebook_lead.disposition_name
              row.promotion_name = data.facebook_lead.promotion_name
              row.updated_at = data.facebook_lead.updated_at
            }
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onViewCommunicationDetail (current) {
      this.selected.log = { ...current }
      this.$bvModal.show('modal-view-communication-detail')
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
